






import EditVoucherStepper from "@/components/voucher/EditVoucherStepper.vue";
import Vue from "vue";

export default Vue.extend({
  components: { EditVoucherStepper },
  name: "EditVoucherForm",
  props: {
    voucherId: {
      type: String,
      required: true,
    },
  },
});
