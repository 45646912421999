








































































































































































































































import Vue from "vue";

import serviceStoreModule from "@/store/modules/service";
import productStoreModule from "@/store/modules/productInventory";
import voucherStoreModule from "@/store/modules/voucher";
import { createNamespacedHelpers } from "vuex";
import { Business, Role, Voucher } from "@/types";

const { mapGetters: serviceGetters, mapActions: serviceActions } =
  createNamespacedHelpers("EDIT_VOUCHER_FORM_SERVICE_LIST");

const { mapGetters: productGetters, mapActions: productActions } =
  createNamespacedHelpers("EDIT_VOUCHER_FORM_PRODUCT_LIST");

const { mapActions: voucherActions } =
  createNamespacedHelpers("EDIT_VOUCHER_FORM");

export default Vue.extend<any, any, any, any>({
  name: "voucherClassForm",
  props: {
    voucherId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      e1: 1,
      steps: 3,
      title: "Add voucher information",
      currency: "KES",
      name: "",
      value: 0,
      retailPrice: 0,
      validityPeriod: 0,
      limitSales: true,
      saleLimit: 1,
      voucherTitle: "",
      description: "",
      serviceIds: [] as string[],
      validityPeriods: [
        { value: 1, label: "1 Month" },
        { value: 3, label: "3 Months" },
        { value: 4, label: "4 Months" },
        { value: 6, label: "6 Months" },
        { value: 9, label: "9 Months" },
        { value: 12, label: "1 Year" },
      ],
      nameRules: [(v: string) => !!v || "Name field is required"],
      valueRules: [(v: string) => !!v || "Value field is required"],
      validityPeriodRules: [
        (v: string) => !!v || "Valid for field is required",
      ],
      retailPriceRules: [],
      saleLimitRules: [(v: string) => !!v || "Sale limit field is required"],
      serviceRules: [(v: string) => !!v || "Services field is required"],
      titleRules: [],
      descriptionRules: [],
      includeAllServices: false,
      colors: [
        "#FF8200",
        "#252733",
        "#FF0000",
        "#21E04B",
        "#FFE6CC",
        "#B629F8",
        "#FFD54F",
        "#2134E0",
      ],
      color: "",
      productIds: [] as string[],
      includeAllProducts: false,
      productRules: [(v: string) => !!v || "Products field is required"],
    };
  },
  watch: {
    role: "fetchData",
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    e1(val) {
      if (val === 2) this.title = "Customise your voucher typ";
      else this.title = "Add voucher information";
    },
    includeAllServices() {
      if (this.includeAllServices) {
        this.serviceIds = this.servicePage.docs.map((item) => item._id);
      }
    },
    includeAllProducts() {
      if (this.includeAllProducts) {
        this.productIds = this.productPage.docs.map((item) => item._id);
      }
    },
  },
  computed: {
    ...serviceGetters(["servicePage"]),
    ...productGetters(["productPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.fetchVoucher(`?voucherId=${this.voucherId}`).then(
      (voucher: Voucher) => {
        if (voucher) {
          this.name = voucher.name;
          this.value = voucher.value;
          this.retailPrice = voucher.retailPrice;
          this.validityPeriod = voucher.validityPeriod;
          this.limitSales = voucher.limitSales;
          this.saleLimit = voucher.saleLimit;
          this.voucherTitle = voucher.title;
          this.description = voucher.description;
          this.serviceIds = voucher.services.map((item) => item._id);
          this.includeAllServices = voucher.includeAllServices;
          this.includeAllProducts = voucher.includeAllProducts;
          this.productIds = voucher.products.map((item) => item._id);
          this.color = voucher.color;
        }
      }
    );
  },
  methods: {
    ...serviceActions(["fetchServiceList"]),
    ...productActions(["fetchProductList"]),
    ...voucherActions(["updateVoucher", "fetchVoucher"]),
    nextStep(n: number) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;

        this.fetchServiceList(`?businessId=${bid}&limit=10000`);

        this.fetchProductList(`?businessId=${bid}&limit=10000`);
      }
    },
    validateInfoForm() {
      const valid = (
        this.$refs.infoForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.nextStep(1);
    },
    validateCustomiseForm() {
      const valid = (
        this.$refs.customiseForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.nextStep(2);
    },
    saveVoucher() {
      this.updateVoucher({
        id: this.voucherId,
        voucher: {
          name: this.name,
          value: this.value,
          currency: this.currency,
          retailPrice: this.retailPrice,
          validityPeriod: this.validityPeriod,
          limitSales: this.limitSales,
          saleLimit: this.saleLimit,
          title: this.title,
          description: this.description,
          serviceIds: this.serviceIds,
          includeAllServices: this.includeAllServices,
          includeAllProducts: this.includeAllProducts,
          productIds: this.productIds,
          color: this.color,
        },
      }).then((voucher) => {
        if (voucher) this.$router.push({ path: "/voucher" });
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EDIT_VOUCHER_FORM_SERVICE_LIST")) {
      this.$store.registerModule(
        "EDIT_VOUCHER_FORM_SERVICE_LIST",
        serviceStoreModule
      );
    }
    if (!this.$store.hasModule("EDIT_VOUCHER_FORM_PRODUCT_LIST")) {
      this.$store.registerModule(
        "EDIT_VOUCHER_FORM_PRODUCT_LIST",
        productStoreModule
      );
    }
    if (!this.$store.hasModule("EDIT_VOUCHER_FORM")) {
      this.$store.registerModule("EDIT_VOUCHER_FORM", voucherStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EDIT_VOUCHER_FORM");
    this.$store.unregisterModule("EDIT_VOUCHER_FORM");
  },
});
