






import VoucherStepper from "@/components/voucher/VoucherStepper.vue";
import Vue from "vue";

export default Vue.extend({
  components: { VoucherStepper },
  name: "VoucherForm",
});
